<template>
  <div class="aboutus">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <router-link to="/">
        <img src="../../assets/img/xyyylogo.png" alt="logo" />
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a home-text"
              >关于翔云</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
      <router-link to="/">
        <img src="../../assets/img/xyyylogo.png" alt="logo" />
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a"
              >智慧营业厅</router-link
            >
          </li>
          <li>
            <router-link to="aboutus" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a"
              >互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a home-text"
              >关于翔云</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <!-- 大屏显示 -->
    <div class="aboutus-screen">
      <img src="../../assets/aboutUs/banner.png" alt="" />
    </div>
    <div class="aboutus-tab">
      <!-- 选项卡部分 -->
      <div class="tab-header">
        <div
          class="header-text"
          :class="{ textChange: index == dynamic }"
          @click="check(index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <h3>{{ item.title }}</h3>
        </div>
      </div>
      <!-- 展示部分 -->
      <div class="tab-spread">
        <!-- 关于翔云 -->
        <div v-show="TabId === 0" class="spread-content">
          <div class="content-text">
            <p>{{ tabList[0].textup }}</p>
            <p>{{ tabList[0].textdown }}</p>
          </div>
          <div class="content-img">
            <img :src="tabList[0].img" />
          </div>
        </div>
        <!-- 服务优势 -->
        <div v-show="TabId === 1" class="spread-service">
          <div class="service-up">
            <div class="up-left">
              <img :src="tabList[1].imgone" alt="" />
              <p>{{ tabList[1].textone }}</p>
            </div>
            <div class="up-right">
              <img :src="tabList[1].imgtwo" alt="" />
              <p>{{ tabList[1].texttwo }}</p>
            </div>
          </div>
          <div class="service-down">
            <img :src="tabList[1].imgthree" alt="" />
            <p>{{ tabList[1].textthree }}</p>
          </div>
        </div>
        <!-- 加入翔云 -->
        <div v-show="TabId === 2" class="spread-join">
          <!-- 渐变盒子 -->
          <div class="gradient" ref="gradients"></div>
          <div class="join-father">
            <div class="join-son">
              <div
                class="jion-introduce"
                v-for="(joinShow, index) in tabList[2].text"
                :key="index"
              >
                <div class="introduce-title">
                  <p>{{ joinShow.titleone }}</p>
                  <div @click="open(index)">
                    <img
                      :src="tabList[2].imgadd"
                      alt=""
                      v-if="!joinShow.imgShow"
                    />
                    <img
                      :src="tabList[2].imgreduce"
                      alt=""
                      v-if="joinShow.imgShow"
                    />
                  </div>
                </div>
                <div class="introduce-content" v-show="joinShow.imgShow">
                  <h3>{{ tabList[2].introducetitle }}</h3>
                  <p
                    :key="index"
                    v-for="(joinText, index) in joinShow.joinInfo"
                     :class="{ 'gradientText' :  12 === index}"
                  >
                    {{ joinText }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="join-image">
            <img src="../../assets/aboutUs/joinUs.png" alt="" />
          </div>
        </div>
        <!-- 联系我们 -->
        <div v-show="TabId === 3" class="spread-contactUs">
          <div class="contactUs-text">
            <h1>联系我们</h1>
            <div class="text-style">
              <h3>商务合作：</h3>
              <p>sales@orbyun.com</p>
            </div>
            <div class="text-style">
              <h3>投递简历：</h3>
              <p>hr@orbyun.com</p>
            </div>
          <div class="text-style">
              <h3>公司地址：</h3>
              <p>北京市丰台区南四环西路186号<br />汉威国际广场三区1号楼6层604</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="aboutus-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
              <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false, // 控制导航栏上拉显示下拉隐藏
      top: "", // 获取top值
      // 选项卡
      TabId: 0, //默认0
      dynamic: 0,
      gradient: "", //获取加入我们第几个展开
      gradientShow: "", //获取展开状态
      tabList: [
        {
          title: "关于翔云",
          textup:
            "北京翔云在线数据技术有限公司（翔云数据）成立于2014年11月，致力于金融科技创新，深刻了解用户需求，以交易数据为核心，覆盖客户全生命周期数据，为金融行业提供新一代的智能应用服务。",
          textdown:
            "目前已成功应用于金融行业的产品包括：交易数据为核心的大数据管理平台、智能APP（全品种交易、智能开户、智能分析、智能运营、智能认证）及通过智能金融设备实现线上线下融合服务。",
          img: require("../../assets/aboutUs/aboutUs.png"),
        },
        {
          title: "服务优势",
          textone: "专业团队",
          texttwo: "高效机制",
          textthree: "成功经验",
          imgone: require("../../assets/aboutUs/service_one.png"),
          imgtwo: require("../../assets/aboutUs/service_two.png"),
          imgthree: require("../../assets/aboutUs/service_three.png"),
        },
        {
          title: "加入翔云",
          imgadd: require("../../assets/aboutUs/add.png"),
          imgreduce: require("../../assets/aboutUs/reduce.png"),
          introducetitle: "招聘要求：",
          text: [
            {
              imgShow: true,
              titleone: "前端工程师",
              joinInfo: [
                "1、统招本科及以上学历，3年以上前端开发经验；",
                "2、精通HTML5、CSS3、ES6、JS，AJAX 等基础技术，能够很好解决浏览器兼容性；",
                "3、精通VUE，JQUERY等JS框架，熟练使用其进行后端接口调用，并对跨域问题能熟练解决；",
                "4、对JS技术有深入理解，能够独立进行JS技术封装者优先；",
                "5、了解websocket技术（不强制）；",
                "6、对用户体验、交互操作流程、及用户需求有一定了解，并具有良好的代码风格、接口设计与程序架构能力；",
                "7、具备良好的服务意识、责任心、较强的学习能力、优秀的团队沟通与协作能力；",
                "8、有带队经验优先考虑；",
              ],
            },
            {
              imgShow: false,
              titleone: "java研发工程师",
              joinInfo: [
                "1、有良好的JAVA基础，熟练掌握面向对象思想，熟悉web编程。有良好的JAVA基础，熟练掌握面向对象思想，熟悉web编程。",
                "2、熟练使用集合、IO流及多线程；",
                "3、熟练JSP、Servlet、jdbc等WEB开发技术；",
                "4、熟悉应用框架SSM、SSH、dubbo 等框架。 了解框架运行原理；",
                "5、熟悉应用jQuery、ExtJs、bootstarp、react 等前端框架进行编程；",
                "6、熟练应用Oracle、SQLserver、mysql、redis 等数据库, 掌握SQL程序语言；",
                "7、熟悉XML、Dom4j解析器等；",
                "8、了解并会使用maven进行项目管理；",
                "9、 能写前端代码，能使用合适的结构编写兼容主流浏览器的页面布局。熟悉手写符合w3c标准的HTML页面 ，熟悉使用 H5 ，熟悉 Bootstrap 前端框架。能熟练使用Chrome开发者工具、FireBug、Fiddler等工具辅助开发",
                "10、必备技能：做过柜台接口调用开发；做过新版深沪行情源接入；熟练掌握java-socket编程；理解各品种证券交易业务；能配合运维人员做交易功能调用的故障分析处理。",
                "11、了解微信系统开发；",
                "12、了解SOA概念；",
                "13、了解软件工程过程，有带小组开发经验的，优先考虑；",
              ],
            },
          ],
        },
        {
          title: "联系我们",
          // text: "444",
        },
      ],
    };
  },
  created() {},
  mounted() {
    //获取浏览器滚动
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
    // console.log(this.top,"totoptop");
  },
  methods: {
    //将点击事件获取的下标ID传递给要切换的盒子
    check(index) {
      this.TabId = index;
      this.dynamic = index;
    },
    // 招聘信息点击
    open(index) {
      let imgShow = this.tabList[2].text[index].imgShow;
      this.$set(this.tabList[2].text[index], "imgShow", !imgShow);
      this.gradient = index;
      this.gradientShow = imgShow;
      if (this.gradient == 1) {
        if (this.gradientShow == false) {
          this.$refs.gradients.style.display = "block";
        } else {
          this.$refs.gradients.style.display = "none";
        }
      }
    },
  },
  // 监听top值的变化
  watch: {
    top(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      // console.log(this.top);
      if (newValue < oldValue) {
        if (this.top <= 300) {
          this.isShow = false;
        } else {
          // 向上滚动
          this.isShow = true;
        }
      } else {
        // this.navShow = true
        this.isShow = false;
      }
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "AboutUs.css";
.aboutus {
  .textChange {
    border-bottom: 4px solid #2980c3;
    font-weight: bold;
  }
  .gradientText {
    padding-bottom: 30px;
  }
}
</style>