<template>
  <div class="Business-hall">
    <!--固定 头部 -->
    <div class="navigation-header navigation-header-absolute">
      <!-- logo -->
      <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a home-text">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a">互联网终端</router-link
            >
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!--移动头部 -->
    <div
      class="navigation-header navigation-header-bg"
      :style="isShow ? `top:0px;opacity: 0.7;` : `top:-100px;opacity: 0.8;`"
    >
      <!-- logo -->
      <router-link to="/">
          <img src="../../assets/img/xyyylogo.png" alt="logo">
      </router-link>
      <!-- 导航栏 -->
      <nav class="navigation-nav">
        <ul>
          <li>
            <router-link to="BusinessHall" class="home-a home-text">智慧营业厅</router-link>
          </li>
          <li>
            <router-link to="Metaverse" class="home-a">元宇宙</router-link>
          </li>
          <li>
            <router-link to="BigData" class="home-a">大数据</router-link>
          </li>
          <li>
            <router-link to="InternetTerminal" class="home-a">互联网终端</router-link>
          </li>
          <li>
            <router-link to="AboutUs" class="home-a">关于翔云</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <!-- 智慧营业厅大屏 -->
    <div class="Business-screen">
      <img src="../../assets/BusinessHall/tt.png" alt="智慧营业厅">
      <div class="screen-text" id="screen_text">
        <h1>智慧营业厅</h1>
        <p>为金融行业全力打造智慧网点，终端（手机、PAD）预约业务，机器人引导、VTM业务办理，标准化智慧服务、节省人力成本、优质体验、智能推荐、扩展业务。</p>
      </div>
      <div class="screen-down">
        <img src="../../assets/BusinessHall/yz.png" alt="智慧营业厅拼接部分">
      </div>
    </div>
    <!-- 智慧营业厅三剑客 -->
    <div class="Business-tab">
      <p class="tab-text">智慧营业厅三剑客</p>
      <div class="tab-box">
        <div class="box-one">
          <img src="../../assets/BusinessHall/robot.png" alt="智慧金融机器人">
          <p>智慧金融机器人</p>
        </div>
        <div class="box-two">
          <img src="../../assets/BusinessHall/Teller_machine.png" alt="VTM智能柜员机">
          <p>VTM智能柜员机</p>
        </div>
        <div class="box-three">
          <img src="../../assets/BusinessHall/Guide_Tai.png" alt="智能引导台">
          <p>智能引导台</p>
        </div>
      </div>
    </div>
    <!-- banner图 -->
    <div class="business-banner">
      <el-carousel trigger="click" height="850px" :interval="15000">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <div class="banner-box">
            <div class="banner-text">
              <h3>{{item.headline}}</h3>
              <p>{{item.titleOne}}</p>
              <p>{{item.contentOne}}</p>
              <p>{{item.titleTwo}}</p>
              <p>{{item.contentTwo}}</p>
              <p>{{item.titleThree}}</p>
              <p>{{item.contentThree}}</p>
            </div>
            <div class="banner-img">
              <img :src="item.img" alt="轮播右侧图片">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 应用场景 -->
    <div class="business-scene">
      <h3>应用场景</h3>
      <div class="scene-img">
        <div class="scene-left">
          <img src="../../assets/BusinessHall/scene_1.png" alt="">
        </div>
        <div class="scene-right">
          <img src="../../assets/BusinessHall/scene_2.png" alt="">
          <img src="../../assets/BusinessHall/scene_3.png" alt="" class="scene-top">
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="business-footer">
      <div class="footer-content">
        <div class="footer-left">
          <img src="../../assets/img/翔云logo.png" alt="" />
              <p>地址 : 北京市丰台区南四环西路186号汉威国际广场三区1号楼6层604</p>
        </div>
        <div class="footer-right">
          <img src="../../assets/img/weixin.jpg" alt="" />
          <p>官方微信</p>
        </div>
      </div>
      <div class="footer-foot">
        版权所有 © 北京翔云在线数据技术有限公司
        网站备案/许可证号：京ICP备15021951号-2 京公网安备 11010802023249号
      </div>
    </div>
  </div>
</template>

<script>
export default {
 data(){
 return{
  isShow: false, // 控制导航栏上拉显示下拉隐藏
  top: "", //获取top值
  bannerList: [
        {
          img: require("../../assets/BusinessHall/VTM.png"),
          headline: "VTM智能柜员机",
          titleOne: "1、科技赋能，体验为王",
          contentOne:"VTM智能柜员机有立式柜员机和桌面柜员机可供用户选择，均整合了人脸识别、语音识别、OCR、智能审核、无纸化签名等多种AI服务，匠心科技，只为满足不同的用户体验。",
          titleTwo: "2、功能多样化构筑一站式体验",
          contentTwo:"八大类40项业务，高频业务覆盖率超过90%；八大类业务：个人信息管理，适当性业务，银证转账，账户管理，中登业务，预约双录，港澳台个人业务，其他业务。",
          titleThree: "3、全自助业务办理",
          contentThree:"VTM智慧柜员机在业务办理过程中符合客户自助办理操作习惯，办理过程可见，可自主选择办理业务",
        },
        {
          img: require("../../assets/BusinessHall/VTM.png"),
          headline: "智慧金融机器人",
          titleOne: "1、个性化迎宾",
          contentOne:"实现对指定人脸库的VIP（如已开户）识别，让机器人完成对用户身份的识别确定；非VIP用户可以识别客户性别等，更加贴心的问候，让客户有宾至如归的感觉。",
          titleTwo: "2、业务咨询、互动问答",
          contentTwo:"丰富的智能知识库，完美的语音体验交互，深度学习，答您所问一切想知道的问题。",
          titleThree: "3、交互控制",
          contentThree:"通过中控层，使得机器人可以在不同类型对话中进行自由的意图切换，针对不同类型问题进行语音、动作、UI界面等不同类型的交互，用户体验更加自然。",
        },
        {
          img: require("../../assets/BusinessHall/VTM.png"),
          headline: "智能引导台",
          titleOne: "1、操作便捷，提升用户体验",
          contentOne:"完善操作指引，为客户提供全面、人性化的自助服务，降低客户的操作成本，提高营业部服务水平和客户体验。",
          titleTwo: "2、功能完善，满足用户需求",
          contentTwo:"智能引导台可满足个人用户和机构用户操作，集成了排队叫号、播放投教视频，客服智能互动问答以及客户业务开通状态和适当性查询、客户信息查询、交割单和对账单的查询打印等功能，让客户更加方便、详细地了解账户的信息以及相关的产品和业务。",
          titleThree: "3、提高服务效率",
          contentThree:"通过以智能设备和信息化技术为媒介，在满足合规的基础上，以优化、创新、融合技术服务为引导程序，对全业务实现标准化、自动化、引导化的服务。",
        },
      ],
 }
 },
 created(){},
 methods:{},
 mounted() {
  //获取浏览器滚动
    window.addEventListener("scroll", () => {
      this.top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
 },
 // 监听top值的变化
  watch: {
    top(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      console.log(this.top);
      if (newValue < oldValue) {
        if (this.top <= 300) {
          this.isShow = false;
        } else {
          // 向上滚动
          this.isShow = true;
        }
      } else {
        // this.navShow = true
        this.isShow = false;
      }
    },
  },
 components:{}
}

</script>
<style scoped lang="scss">
@import "../../utlis/reset.css";
@import "../../utlis/navigation.css";
@import "BusinessHall.css";
.Business-hall {
  .el-carousel {
    ::v-deep {
      .el-carousel__container {
        .el-carousel__arrow--right,
        .el-carousel__arrow--left {
          display: none;
        }
      }
      .el-carousel__indicator {
        .el-carousel__button {
          // width: 130px;
          // height: 10px;
          // opacity: 0.5;
        }
        .is-active {
          // background-color: red;
        }
      }
    }
  }
}
</style>